import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { CornerEffect } from '../AnimationCorner/AnimationCorner';
import MarkerIcon from '../../assets/icons/markerIcon.svg';

const Wrapper = styled(CornerEffect)`
	width: 80%;
	height: 40vh;
	margin: 0 auto;
`;
const Icon = styled(MarkerIcon)`
	width: 35px;
	height: 35px;
`;
export default function SimpleMap() {
	const defaultProps = {
		center: {
			lat: 54.3769,
			lng: 18.61026
		},
		zoom: 15
	};

	return (
		<Wrapper>
			<GoogleMapReact
				bootstrapURLKeys={{
					key: 'AIzaSyCSTOroA-l9HIJxr8xvqPfNTJSZOGbBBv4'
				}}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}>
				<a
					lat={54.376054}
					lng={18.6089013}
					href='https://www.google.com/maps/dir//OWS+Stomatologia.+Gda%C5%84sk,+Wrzeszcz./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x46fd75dcd3fd99e5:0xc366f33abb67162d?sa=X&ved=2ahUKEwj76Pf5_MCAAxWNs4sKHXwFDIAQ9Rd6BAhIEAA&ved=2ahUKEwj76Pf5_MCAAxWNs4sKHXwFDIAQ9Rd6BAhVEAU'>
					<Icon />
				</a>
			</GoogleMapReact>
		</Wrapper>
	);
}
