import React from 'react';
import { HighlightedHeading } from '../components/HighlightedHeading/HighlightedHeading';

import MailIcon from 'assets/icons/mail.svg';
import AddressIcon from 'assets/icons/addressIcon.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import {
	ContactContentWrapper,
	ContactInfoWrapper,
	MapContainer,
	StyledListContact,
	ContactDetailsWrapper
} from '../assets/styles/pages/kontakt.styles';
import { ContactForm } from '../components/ContactForm/ContactForm';

import SimpleMap from '../components/Map/SimpleMap';
import SEO from '../components/SEO/SEO';

export const Head = () => (
	<>
		<SEO
			title='Stomatolog spółdzielnia - Optymalny dentysta Gdańsk'
			description='Zapraszamy do kontaktu z Gabinetem Stomatologicznym spółdzielnia w Gdańsku na alei Grunwaldzkiej 68. Zapisz się do dentysty Stomatolog spółdzielnia lekarska.'
		/>
	</>
);

const Kontakt = () => {
	return (
		<ContactContentWrapper
			initial={{
				clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
				transition: { duration: 0.4 }
			}}
			animate={{
				clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',

				transition: { duration: 0.8, delay: 0.8 }
			}}
			exit={{
				clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
				transition: { duration: 0.2 }
			}}>
			<ContactInfoWrapper>
				<HighlightedHeading>Odwiedź nas</HighlightedHeading>
				<StyledListContact>
					<div>
						<h3>Godziny otwarcia</h3>
						<div>
							<span>Poniedzialek</span>
							<span>08:00-18:00</span>
							<span>Wtorek</span>
							<span>08:00-18:00</span>
							<span>Środa</span>
							<span>08:00-18:00</span>
							<span>Czwartek</span>
							<span>08:00-18:00</span>
							<span>Piątek</span>
							<span>08:00-15:00</span>
							<span>Sobota</span>
							<span>Zamknięte</span>
							<span>Niedziela</span>
							<span>Zamknięte</span>
						</div>
					</div>
				</StyledListContact>
			</ContactInfoWrapper>

			<MapContainer>
				{' '}
				<SimpleMap
					color='beige'
					offset='2000'
					position='bottomRight'
					size='100px'
					distance='30px'
				/>
			</MapContainer>
			<ContactDetailsWrapper>
				<div>
					<h3>Adres</h3>
					<a href='https://www.google.com/maps/place/OWS+Stomatologia.+Gda%C5%84sk,+Wrzeszcz./@54.376054,18.6089013,17z/data=!3m1!4b1!4m6!3m5!1s0x46fd75dcd3fd99e5:0xc366f33abb67162d!8m2!3d54.3760509!4d18.6114762!16s%2Fg%2F11q23ktz8f?hl=pl-PL&entry=ttu'>
						<AddressIcon />
						Józefa Wassowskiego 2/2 piętro
						<br />
						80-225 Gdańsk
					</a>
				</div>
				<div>
					<h3>Kontakt</h3>
					<a href='mailto:ows.stomatologia@gmail.com'>
						<MailIcon />
						ows.stomatologia@gmail.com
					</a>
					<a href='tel:605439089'>
						{' '}
						<PhoneIcon /> 605 439 089
					</a>
				</div>
			</ContactDetailsWrapper>
			<ContactForm />
		</ContactContentWrapper>
	);
};

export default Kontakt;
